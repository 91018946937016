<template>
    <div class="card-tail card-f-h">
        <div class="border-b pb-2 mb-5">
            <p class="text-2xl">{{ formData.companyName }} ({{ formData.registrationNumber }}) {{ formData.räkenskapsår }}</p>
        </div>

        <el-alert v-if="!formData.harBokförtAktiekapital" title="Varning" type="warning" effect="dark" class="mb-6">Aktiekapital ej bokfört!</el-alert>
        <el-alert v-if="formData.årsslutGjort" title="Varning" type="warning" effect="dark" class="mb-6">Årsavslut är redan gjort, vänd 8811, 8910 och 8999 om ni vill göra om årsavslutet!</el-alert>
        <el-alert v-if="formData.harPensionsförsäkringspremie" title="Kontrollera" type="info" effect="dark" class="mb-6">Bolaget har betalt pensionsförsäkringspremie, avstämd?</el-alert>
        <el-alert v-if="formData.harInventarier" title="Kontrollera" type="info" effect="dark" class="mb-6">Bolaget har inventarier, har avskrivning skett?</el-alert>
        <el-alert v-if="!formData.årsslutGjort && formData.k2099UbHasValue" title="Kontrollera" type="info" effect="dark" class="mb-6">Har ni bokfört föregående års resultat?</el-alert>

        <div class="border-b pb-2 mb-5 grid grid-cols-2 print:grid-cols-1 print:text-xs">
            <el-form label-position="left" label-width="350px" :model="formData">
                <div class="mb-6">
                    <p class="text-xl text-blue-400 uppercase font-semibold mb-4">Avsättning till periodiseringsfond</p>
                    <el-form-item label="Resultat före skatt" class="el-input--mini">
                        +&nbsp;<InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.resultatFöreSkatt" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Förlust föregående år (hämta från SKV)" class="el-input--mini">
                        -&nbsp;&nbsp;<InputCurrency v-model="formData.förlustFöregåendeÅr" custom-class="el-input__inner w-[120px]" :precision="0" />
                    </el-form-item>
                </div>

                <div class="mb-6">
                    <p class="text-xl mb-4">Ej avdragsgilla kostnader</p>
                    <el-form-item label="Representation (6072)" class="el-input--mini">
                        + <InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.representation6072" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Direktpensionlösning (7421)" class="el-input--mini">
                        + <InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.direktpensionlösning7421" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Sjuk och hälsovård (7622)" class="el-input--mini">
                        + <InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.sjukOchHälsovård7622" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Övriga kostnader (6992)" class="el-input--mini">
                        + <InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.övrigaKostnader6992" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Förseningsavgifter (8423)" class="el-input--mini">
                        + <InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.förseningsavgifter8423" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Lämnadebidragochgåvor (6993)" class="el-input--mini">
                        + <InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.lämnadebidragochgåvor6993" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Manuell kostnad" class="el-input--mini">
                        + <InputCurrency class="el-input__inner w-[120px]" v-model="formData.manuelltKostnad" :precision="0" />
                    </el-form-item>
                </div>
                <p class="text-xl mb-4">Schablonintäkt och uppräkning</p>
                <el-form-item label="Schablonintäkt på periodiska fonder vid årets ingång 1,94%" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="schablonintäktPåPeriodiskaFonderVidÅretsIngång" :precision="0" />
                </el-form-item>
                <el-form-item label="Uppräkning av återförd fond" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="periodCountUp" :precision="0" />
                </el-form-item>

                <div>
                    <p class="text-xl mb-4">Ej skattepliktiga intäkter</p>
                    <el-form-item label="Utdelningarfrånintresseföretag (8112)" class="el-input--mini">
                        - <InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.utdelningarfrånintresseföretag8112" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Utdelningpåandelaridotterföretag (8012)" class="el-input--mini">
                        - <InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.utdelningpåandelaridotterföretag8012" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Ränteintäkter skattekontot (8314)" class="el-input--mini">
                        - <InputCurrency class="el-input__inner w-[120px]" disabled v-model="formData.ränteintäkterSkattekontot8314" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Manuell intäkt" class="el-input--mini"> - <InputCurrency class="el-input__inner w-[120px]" v-model="formData.manuelltIntäkt" :precision="0" /> </el-form-item>
                    <el-form-item label="Total" class="el-input--mini"> =&nbsp;<InputCurrency class="el-input__inner w-[120px]" disabled :value="total" :precision="0" /> </el-form-item>
                    <el-form-item label="Max avsättning (25%)" class="el-input--mini">
                        =&nbsp;<InputCurrency class="el-input__inner w-[120px]" disabled :value="maxAvsättning" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Vald avsättning" class="border-gray-900 border-2 p-2 el-input--mini">
                        <InputCurrency class="el-input__inner w-[120px]" v-model="formData.manuelltAvsättning" :precision="0" />
                    </el-form-item>
                </div>
            </el-form>
            <!-- </div>
            <div class="border-b pb-2 mb-5 grid grid-cols-2 print:grid-cols-1"> -->
            <el-form v-if="formData.periodEntries" label-position="left">
                <p class="text-xl mb-4">Periodiseringar årsvis</p>
                <el-alert v-if="formData.konto2130Ib != 0 || formData.konto2130P != 0" title="Felaktigt konto" type="warning" effect="dark">Konto 2130 har använts! flytta till rätt konto!</el-alert>

                <table>
                    <tr>
                        <td colspan="2">
                            <p class="text-right pr-5">IB</p>
                        </td>
                        <td>
                            <p class="text-right pr-2">Perioden</p>
                        </td>
                        <td>
                            <p class="text-right pr-2" />
                        </td>
                        <td>
                            <p class="text-right pr-2">Uppräkning</p>
                        </td>
                    </tr>
                    <tr v-for="entry in formData.periodEntries" :key="entry.year">
                        <td class="h-4 pb-4 w-40">
                            <p class="text-sm mt-1">
                                {{ entry.year }}
                            </p>
                        </td>
                        <td class="h-4 pr-4 pb-4">
                            <InputCurrency class="el-input--mini el-input__inner h-7 w-[100px]" disabled :value="entry.opening" :precision="0" />
                        </td>
                        <td class="h-4 pr-4 pb-4">
                            <InputCurrency class="el-input--mini el-input__inner h-7 w-[100px]" disabled :value="entry.period" :precision="0" />
                        </td>
                        <td class="h-4 pr-4 pb-4">
                            {{ entry.countUpFactor }}
                        </td>
                        <td class=" pb-4">
                            <InputCurrency class="el-input--mini el-input__inner h-7 w-[100px]" disabled :value="entry.countUpAmount" :precision="0" />
                        </td>
                    </tr>
                    <tr>
                        <td class="h-4 pb-4 w-40">
                            <p class="text-sm mt-1">
                                Summa
                            </p>
                        </td>
                        <td class="h-4 pr-4 pb-4">
                            <InputCurrency class="el-input--mini el-input__inner h-7 w-[100px]" disabled :value="openingSum" :precision="0" />
                        </td>
                        <td class="h-4 pr-4 pb-4">
                            <InputCurrency class="el-input--mini el-input__inner h-7 w-[100px]" disabled :value="periodSum" :precision="0" />
                        </td>
                        <td class="h-4 pr-4 pb-4" />
                        <td class=" pb-4">
                            <InputCurrency class="el-input--mini el-input__inner h-7 w-[100px]" disabled :value="periodCountUp" :precision="0" />
                        </td>
                    </tr>
                </table>
            </el-form>
        </div>
        <div class="page-break" />
        <div class="border-b pb-2 mb-5 grid grid-cols-2 print:grid-cols-1">
            <el-form label-position="left" label-width="340px" :model="formData">
                <div class="mb-4">
                    <p class="text-xl tex2-blue-400 uppercase font-semibold">Beräkna skatt för {{ formData.räkenskapsår }}</p>
                </div>
                <div class="mb-5">
                    <el-form-item label="Resultat före skatt efter periodisering" class="el-input--mini">
                        +&nbsp;<InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.resultatFöreSkatt - formData.manuelltAvsättning" :precision="0" />
                    </el-form-item>
                    <el-form-item label="Förlust föregående år (hämta från SKV)" class="el-input--mini">
                        -&nbsp;&nbsp;<InputCurrency :value="formData.förlustFöregåendeÅr" disabled custom-class="el-input__inner w-[120px]" :precision="0" />
                    </el-form-item>
                </div>

                <p class="text-xl mb-2">Ej avdragsgilla kostnader</p>
                <el-form-item label="Representation (6072)" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.representation6072" :precision="0" />
                </el-form-item>
                <el-form-item label="Direktpensionlösning (7421)" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.direktpensionlösning7421" :precision="0" />
                </el-form-item>
                <el-form-item label="Sjuk och hälsovård (7622)" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.sjukOchHälsovård7622" :precision="0" />
                </el-form-item>
                <el-form-item label="Övriga kostnader (6992)" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.övrigaKostnader6992" :precision="0" />
                </el-form-item>
                <el-form-item label="Förseningsavgifter (8423)" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.förseningsavgifter8423" :precision="0" />
                </el-form-item>
                <el-form-item label="Lämnadebidragochgåvor (6993)" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.lämnadebidragochgåvor6993" :precision="0" />
                </el-form-item>
                <el-form-item label="Manuell kostnad" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.manuelltKostnad" :precision="0" />
                </el-form-item>

                <p class="text-xl mb-2">Schablonintäkt och uppräkning</p>
                <el-form-item label="Schablonintäkt på periodiska fonder vid årets ingång 1,94%" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="schablonintäktPåPeriodiskaFonderVidÅretsIngång" :precision="0" />
                </el-form-item>
                <el-form-item label="Uppräkning av återförd fond" class="el-input--mini">
                    + <InputCurrency class="el-input__inner w-[120px]" disabled :value="periodCountUp" :precision="0" />
                </el-form-item>

                <p class="text-xl mb-2">Ej skattepliktiga intäkter</p>
                <el-form-item label="Utdelningarfrånintresseföretag (8112)" class="el-input--mini">
                    - <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.utdelningarfrånintresseföretag8112" :precision="0" />
                </el-form-item>
                <el-form-item label="Utdelningpåandelaridotterföretag (8012)" class="el-input--mini">
                    - <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.utdelningpåandelaridotterföretag8012" :precision="0" />
                </el-form-item>
                <el-form-item label="Ränteintäkter skattekontot (8314)" class="el-input--mini">
                    - <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.ränteintäkterSkattekontot8314" :precision="0" />
                </el-form-item>
                <el-form-item label="Manuell intäkt" class="el-input--mini">
                    - <InputCurrency class="el-input__inner w-[120px]" disabled :value="formData.manuelltIntäkt" :precision="0" />
                </el-form-item>
                <el-form-item label="Underlag för skatt" class="el-input--mini">
                    =&nbsp;<InputCurrency class="el-input__inner w-[120px]" disabled :value="totalUnderlagSkatt" :precision="0" />
                </el-form-item>
                <el-form-item label="Underlag för skatt avrundat" class="el-input--mini">
                    =&nbsp;<InputCurrency class="el-input__inner w-[120px]" disabled :value="totalUnderlagSkattAvrundat" :precision="0" />
                </el-form-item>
                <el-form-item :label="'Skatt (' + formData.bolagsskatt + '%)'" class="border-gray-900 border-2 p-2 el-input--mini">
                    =&nbsp;<InputCurrency class="el-input__inner w-[120px]" disabled :value="skatt" :precision="0" />
                </el-form-item>
                <el-form-item label="Resultat efter skatt" class="border-gray-900 border-2 p-2 el-input--mini">
                    =&nbsp;<InputCurrency class="el-input__inner w-[120px]" disabled :value="resultat" :precision="2" />
                </el-form-item>
            </el-form>
        </div>
        <div class="flex flex-col border-b pb-2 mb-5 w-1/2 print:hidden">
            <div class="mb-4">
                <p class="text-xl text-blue-400 uppercase font-semibold">Bokför</p>
            </div>
            <div v-if="fundVoucher != null" class="mb-14">
                <Table :items-data="fundVoucher" :accounts-data="accountsData" header="Avsättning periodiseringsfond" />
            </div>
            <div v-if="taxVoucher != null" class="mb-14">
                <Table :items-data="taxVoucher" :accounts-data="accountsData" header="Bolagsskatt" />
            </div>
            <div class="mb-14">
                <Table :items-data="resultVoucher" :accounts-data="accountsData" header="Årets resultat" />
            </div>

            <div class="flex">
                <el-button :disabled="formData.årsslutGjort" class="hideprint" type="primary" @click="print">
                    Skrivut
                </el-button>
                <el-button :disabled="formData.årsslutGjort" class="hideprint" type="primary" @click="book">
                    Bokför
                </el-button>
                <el-button :disabled="!formData.årsslutGjort" class="hideprint" type="primary" @click="markAsDone">
                    Markera som klar
                </el-button>
            </div>
        </div>
        <!-- <pre>{{ itemDetails }}</pre> -->
    </div>
</template>
<script>
import Api from "./yearEndDisposition.api";

export default {
    data() {
        return {
            itemId: this.$route.params.id,
            itemDetails: {},
            accountsData: [],
            formData: {
                avsättningskonto: 0,
                datum: "",
                companyName: "",
                registrationNumber: "",
                räkenskapsår: "",
                avsättningTillPeriodiseringsfond: 0,
                resultatFöreSkatt: 0,
                resultatFöreSkattRaw: 0,
                förlustFöregåendeÅr: 0,
                representation6072: 0,
                direktpensionlösning7421: 0,
                sjukOchHälsovård7622: 0,
                övrigaKostnader6992: 0,
                förseningsavgifter8423: 0,
                lämnadebidragochgåvor6993: 0,
                manuelltKostnad: 0,
                utdelningarfrånintresseföretag8112: 0,
                utdelningpåandelaridotterföretag8012: 0,
                ränteintäkterSkattekontot8314: 0,
                manuelltIntäkt: 0,
                schablonintäktPåPeriodiskaFonderVidÅretsIngång: 0,
                manuelltAvsättning: 0,
                bolagsskatt: 0,
                konto2130Ib: 0,
                konto2130P: 0,
                periodEntries: [],
                årsslutGjort: false,
                k2099UbHasValue: false,
                harInventarier: false,
                harPensionsförsäkringspremie: false,
                harBokförtAktiekapital: true,
            },
        };
    },

    components: {
        InputCurrency: () => import(/* webpackChunkName: "InputCurrency" */ "@/components/InputCurrency.vue"),
        Table: () => import(/* webpackChunkName: "YearEndDispositionDetailsTable" */ "./components/YearEndDispositionDetailsTable.vue"),
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Bokslutsdisposition");
        document.title = "Bokslutsdisposition";
    },

    created() {
        this.getDetails();
    },

    computed: {
        total() {
            let result =
                this.formData.resultatFöreSkatt +
                -this.formData.förlustFöregåendeÅr +
                this.formData.representation6072 +
                this.formData.direktpensionlösning7421 +
                this.formData.sjukOchHälsovård7622 +
                this.formData.övrigaKostnader6992 +
                this.formData.förseningsavgifter8423 +
                this.formData.lämnadebidragochgåvor6993 +
                this.formData.manuelltKostnad +
                this.schablonintäktPåPeriodiskaFonderVidÅretsIngång +
                this.periodCountUp +
                -this.formData.utdelningarfrånintresseföretag8112 +
                -this.formData.utdelningpåandelaridotterföretag8012 +
                -this.formData.ränteintäkterSkattekontot8314 +
                -this.formData.manuelltIntäkt;
            return result;
        },
        totalUnderlagSkatt() {
            let result =
                this.formData.resultatFöreSkatt +
                -this.formData.manuelltAvsättning +
                -this.formData.förlustFöregåendeÅr +
                this.formData.representation6072 +
                this.formData.direktpensionlösning7421 +
                this.formData.sjukOchHälsovård7622 +
                this.formData.övrigaKostnader6992 +
                this.formData.förseningsavgifter8423 +
                this.formData.lämnadebidragochgåvor6993 +
                this.formData.manuelltKostnad +
                this.schablonintäktPåPeriodiskaFonderVidÅretsIngång +
                this.periodCountUp +
                -this.formData.utdelningarfrånintresseföretag8112 +
                -this.formData.utdelningpåandelaridotterföretag8012 +
                -this.formData.ränteintäkterSkattekontot8314 +
                -this.formData.manuelltIntäkt;
            console.log("🚀 - file: YearEndDispositionDetails.vue - line 100 - total - result", result);
            return result;
        },
        totalUnderlagSkattAvrundat() {
            return 10 * Math.floor(this.totalUnderlagSkatt / 10);
        },
        skatt() {
            let result = Math.floor((this.totalUnderlagSkattAvrundat * this.formData.bolagsskatt * 10) / 1000);
            result = result <= 0 ? 0 : result;
            return result;
        },

        resultat() {
            let result = (this.formData.resultatFöreSkattRaw - this.formData.manuelltAvsättning * 100 - this.skatt * 100) / 100;
            console.log("🚀 - file: YearEndDispositionDetails.vue - line 357 - resultat - result", result);
            console.log("🚀 - file: YearEndDispositionDetails.vue - line 358 - resultat - this.skatt", this.skatt);
            console.log("🚀 - file: YearEndDispositionDetails.vue - line 358 - resultat - this.formData.manuelltAvsättning", this.formData.manuelltAvsättning);
            console.log("🚀 - file: YearEndDispositionDetails.vue - line 358 - resultat - this.formData.resultatFöreSkattRaw", this.formData.resultatFöreSkattRaw);
            return result;
        },

        schablonintäktPåPeriodiskaFonderVidÅretsIngång() {
            return Math.floor(-(this.openingSum * 194) / 10000);
        },
        uppräkningAvÅterfördFond() {
            return 1234;
        },
        openingSum() {
            return this.formData.periodEntries.reduce((a, b) => a + b.opening || 0, 0);
        },
        periodSum() {
            return this.formData.periodEntries.reduce((a, b) => a + b.period || 0, 0);
        },
        periodCountUp() {
            return Math.floor(this.formData.periodEntries.reduce((a, b) => a + b.countUpAmount || 0, 0));
        },
        maxAvsättning() {
            let result = Math.floor((this.total * 25) / 100);
            result = result <= 0 ? 0 : result;
            return result;
        },
        fundVoucher() {
            if (this.formData.manuelltAvsättning <= 0) return null;
            let voucher = [];
            voucher.push({ account: 8811, debit: this.formData.manuelltAvsättning, credit: 0 });
            voucher.push({ account: this.formData.avsättningskonto, debit: 0, credit: this.formData.manuelltAvsättning });
            return voucher;
        },
        taxVoucher() {
            if (this.skatt <= 0) return null;
            let voucher = [];
            voucher.push({ account: 8910, debit: this.skatt, credit: 0 });
            voucher.push({ account: 2510, debit: 0, credit: this.skatt });
            return voucher;
        },
        resultVoucher() {
            let voucher = [];
            if (this.resultat < 0) {
                voucher.push({ account: 2099, debit: -this.resultat, credit: 0 });
                voucher.push({ account: 8999, debit: 0, credit: -this.resultat });
            } else {
                voucher.push({ account: 2099, debit: 0, credit: this.resultat });
                voucher.push({ account: 8999, debit: this.resultat, credit: 0 });
            }
            return voucher;
        },
    },

    methods: {
        async getDetails() {
            this.itemDetails = await Api.getDetails(this.itemId);
            this.formData = {
                avsättningskonto: this.itemDetails.avsättningskonto,
                datum: this.itemDetails.datum,
                companyName: this.itemDetails.companyName,
                registrationNumber: this.itemDetails.registrationNumber,
                räkenskapsår: this.itemDetails.räkenskapsår,
                avsättningTillPeriodiseringsfond: this.itemDetails.avsättningTillPeriodiseringsfond,
                resultatFöreSkatt: this.itemDetails.resultatFöreSkatt,
                resultatFöreSkattRaw: this.itemDetails.resultatFöreSkattRaw,
                förlustFöregåendeÅr: this.itemDetails.förlustFöregåendeÅr,
                representation6072: this.itemDetails.ejAvdragsgillaKostnader.representation6072,
                direktpensionlösning7421: this.itemDetails.ejAvdragsgillaKostnader.direktpensionlösning7421,
                sjukOchHälsovård7622: this.itemDetails.ejAvdragsgillaKostnader.sjukOchHälsovård7622,
                övrigaKostnader6992: this.itemDetails.ejAvdragsgillaKostnader.övrigaKostnader6992,
                förseningsavgifter8423: this.itemDetails.ejAvdragsgillaKostnader.förseningsavgifter8423,
                lämnadebidragochgåvor6993: this.itemDetails.ejAvdragsgillaKostnader.lämnadebidragochgåvor6993,
                manuelltKostnad: this.itemDetails.ejAvdragsgillaKostnader.manuelltKostnad,
                utdelningarfrånintresseföretag8112: this.itemDetails.ejSkattepliktigaIntäkter.utdelningarfrånintresseföretag8112,
                utdelningpåandelaridotterföretag8012: this.itemDetails.ejSkattepliktigaIntäkter.utdelningpåandelaridotterföretag8012,
                ränteintäkterSkattekontot8314: this.itemDetails.ejSkattepliktigaIntäkter.ränteintäkterSkattekontot8314,
                manuelltIntäkt: this.itemDetails.ejAvdragsgillaKostnader.manuelltIntäkt,
                manuelltAvsättning: 0,
                ibPeriodiseringsfonder: 0,
                bolagsskatt: this.itemDetails.bolagsskatt,
                periodEntries: this.itemDetails.periodEntries,
                konto2130Ib: this.itemDetails.konto2130Ib,
                konto2130P: this.itemDetails.konto2130P,
                årsslutGjort: this.itemDetails.årsslutGjort,
                k2099UbHasValue: this.itemDetails.k2099UbHasValue,
                harInventarier: this.itemDetails.harInventarier,
                harPensionsförsäkringspremie: this.itemDetails.harPensionsförsäkringspremie,
                harBokförtAktiekapital: this.itemDetails.harBokförtAktiekapital,
            };
            console.log("🚀 - file: YearEndDispositionDetails.vue - line 140 - getDetails - this.formData", this.formData);

            this.getAccountsData();
        },

        async getAccountsData() {
            this.accountsData = await Api.getAccounts(this.itemDetails.clientId);
        },

        async book() {
            const confirm = await this.$dialog.title("Bokför resultat mm").confirm("Är du säker?");
            if (!confirm) return;

            let newObject = {
                fundVoucher: this.fundVoucher,
                taxVoucher: this.taxVoucher,
                resultVoucher: this.resultVoucher,
                manuelltAvsättning: this.formData.manuelltAvsättning,
                maxAvsättning: this.maxAvsättning,
                förlustFöregåendeÅr: this.formData.förlustFöregåendeÅr,
                skatt: this.skatt,
                resultat: this.resultat,
                manuelltKostnad: this.formData.manuelltKostnad,
                manuelltIntäkt: this.formData.manuelltIntäkt,
            };
            await Api.book(this.itemId, newObject);

            this.$notify.success({ title: "Utfört" });
            this.$router.push("/yearEndDisposition");
        },
        async markAsDone() {
            let newObject = {};
            await Api.done(this.itemId, newObject);

            this.$notify.success({ title: "Utfört" });
            this.$router.push("/yearEndDisposition");
        },

        print() {
            window.print();
            return false;
        },
    },
};
</script>
<style scoped>
@media print {
    .hideprint {
        display: none;
    }
    .page-break {
        page-break-after: always;
    }
}
.el-input__inner {
    text-align: right !important;
}
</style>
